<template>
  <div class="m-6 flex flex-col">

        <div v-if="isLoading" class="flex min-h-full min-w-full justify-center items-center rounded-md">
            <loader class="my-5" height="32px" width="32px" :loading="isLoading" />
                    Loading
        </div>
        <div v-if="descriptionData && !isLoading">
                <div class="flex gap-6 flex-col">
                <div class="flex flex-col gap-3">
                    <span class="text-gray-500 text-md font-medium">
                    Screening Package Name
                    </span>
                    <div v-if="editMode">
                    <neo-input
                        style="border: 1px solid #e8e8e8"
                        class="rounded-lg h-10 w-2/6"
                        :value="descriptionData.package_name"
                        placeholder="Field Name"
                        bg="white"
                    >
                    </neo-input>
                    </div>
                    <div v-else>
                    <p class="text-lg font-medium flex items-center gap-3 m-0 border border-solid border-gray-200 w-4/12 cursor-not-allowed p-2 rounded-md">
                        {{ descriptionData.package_name ?  descriptionData.package_name : 'No package name' }}
                        <!-- <font-awesome-icon @click="editMode = true" class="hover:text-primary cursor-pointer" icon="edit" />  -->
                    </p>
                    </div>
                </div>

                <div class="flex flex-col gap-3 w-12/12">
                    <span class="text-gray-500 text-md font-medium"> Description </span>
                    <textarea
                    v-model="descriptionData.package_description"
                    class="rounded-lg max-w-full overflow-hidden p-3 text-gray-600"
                    placeholder="Description"
                    style="border: 1px solid #e8e8e8"
                    id=""
                    cols="30"
                    rows="10"
                    ></textarea>
                </div>
                </div>
            <!-- <div v-else>loading...</div> -->

            <div class="mt-4 flex justify-end max-w-full gap-4">
            <button class="flex btn btn-info" @click="updatePackage">
                <font-awesome-icon icon="check" class="text-xl mr-3" />
                Save
            </button>
            </div>

        </div>
  </div>
</template>
<script>
import Input from "@/components/input";
import axios from "@/axios";
import loader from "@/components/loader";
import { mapActions, mapGetters, mapMutations } from "vuex";


export default {
    name: "description",
    components: {
        "neo-input": Input,
        loader,
    },
    props: [],

    data: () => ({
        editMode: false,
        descriptionData: null,
        isLoading: false,
        // package:  {
        //   id: "",
        //   // package_name: "",
        //   // package_name_internal: "",
        //   package_description: "",
        //   // turn_around_time: null,
        //   // client_email_template: null,
        //   // candidate_email_template: null,
        //   // max_candidate_chase_email: null,
        //   // min_candidate_chase_email: null,
        //   // tenant_id: "3ea5e89b-1d80-4b7d-a4db-852d81126c8d",
        //   // is_custom: false,
        //   // mark_as_default: null
        // }
    }),
    async mounted() {
        await this.fetchScreeningData();
    },

    computed: {
        ...mapGetters(["getPackage"]),
    },
    methods: {
        ...mapActions(["fetchPackageData"]),
        ...mapMutations(["SET_PACKAGE"]),
        async fetchScreeningData() {
        this.isLoading = true;
        console.log(this.getPackage, "package Data")
        if(!this.getPackage){
            await this.fetchPackageData(this.$route.params.packageName)
            this.descriptionData = this.getPackage
        }
        this.descriptionData = this.getPackage
        this.isLoading = false;
        },

        async updatePackage() {
            const package_id = this.$route.params.packageName;

            const url = `/screening-package/${package_id}`;
            const payload = {
                package_description: this.descriptionData.package_description,
            };
            try {
                const data = await axios.put(url, payload);
                console.log(data, "...package update result");
                this.SET_PACKAGE = {...this.getPackage, package_description:this.descriptionData.package_description}
                this.$toast.success("Package description updated.");
            } catch (error) {
                this.$toast.warning("Could not update package.");
            }
        },
    },
};
</script>